import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import b2c2pjpg from '../../assets/img/body/bank/2c2p.jpg'
import { getOS } from 'getOs';

export default function PackRegis(props) {
    const { id } = useParams()
    const [input, setInput] = useState({})
    const [rub, setRub] = useState()
    const [imgLoad, setImgLoad] = useState(false)
    const [gbInput, setGbInput] = useState({})
    const [IsSubmit, setIsSubmit] = useState(false)
    let user_idRef = useRef()
    const osRef = useRef(getOS()[0].toUpperCase())
    var token = window.localStorage.getItem('auth')
    const { jwtDecode } = require("jwt-decode")
    const base64url = require('base64url');

    //console.log(window.localStorage.getItem('auth'))
    //console.log(token)
    //console.log(user_idRef.current)

    if(token!='' && token!='0000000000' && token!=null){

    const decoded = jwtDecode(token)
    //console.log(decoded); 
    //console.log(decoded.exp)
    const jwt_exp = decoded.exp 

    const myCurrentDate = new Date();

    const DateTimeS = Math.floor(new Date().getTime() / 1000)
    //console.log(DateTimeS)
 
    myCurrentDate.setDate(myCurrentDate.getDate()+2);
    const NextDateTimeS = Math.floor(myCurrentDate.getTime() / 1000)
    //console.log(NextDateTimeS);

        if(NextDateTimeS>=jwt_exp){
            alert('กรุณา login ใหม่ก่อนทำการซื้อแพคเกจ')
            window.location.href = '/logout'
        }
    }

    const inputChange = (event) => {
        const { name, value } = event.target
        setInput((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const gbInputChange = (event) => {
        const { name, value } = event.target
        setGbInput((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const paymentNext = (event, product_id, price1, id) => {
        event.preventDefault()
        setIsSubmit(input.payment)
        if (input.payment == 'b2c2p') {
            //paymentSubmit(event, product_id, price1, id)
        }
    }
    const paymentSubmit = async (event, product_id, price1, id) => {
        event.preventDefault()
        const refNo = new Date().getTime() + user_idRef.current.slice(0, 2)
        var formData = new FormData()
        formData.append('referenceNo', refNo)
        formData.append('amount', price1)
        formData.append('customerName', input.email)
        formData.append('customerAddress', '')
        formData.append('customerEmail', input.email)
        formData.append('customerTelephone', input.phone)
        formData.append('package_name', product_id)
        formData.append('session', window.localStorage.getItem('auth'))
        formData.append('user_id', user_idRef.current)
        formData.append('package_id', id)
        await axios.post(`${process.env.REACT_APP_API}/v1/receipt`, formData, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(() => {
        })

        if(input.payment == 'b2c2p') {

            const paymentChannel_2c2p = process.env.REACT_APP_paymentChannel_2c2p.split(',')

            const formData = new FormData()
            formData.append('MERCHANTID', process.env.REACT_APP_MERCHANTID_2c2p)
            formData.append('SECRETKEY', process.env.REACT_APP_SECRETKEY_2c2p)
            formData.append('invoiceNo', refNo)
            formData.append('description', product_id)
            formData.append('amount', '0000000000' +input.pricetest)
            formData.append('currencyCode', 'THB')
            formData.append('paymentChannel', paymentChannel_2c2p)
            formData.append('request3DS', 'Y')
            formData.append('userDefined1', user_idRef.current)
            formData.append('userDefined2', input.email)
            formData.append('userDefined3', input.phone)
            formData.append('userDefined4', 'website')
            formData.append('userDefined5', product_id)
            formData.append('locale', 'th')
            formData.append('frontendReturnUrl', `${process.env.REACT_APP_2c2p_return_gen}`)
            formData.append('backendReturnUrl', `${process.env.REACT_APP_2c2p_return_gen_back}`)
            
            await axios.post(`${process.env.REACT_APP_2c2p_gen}`, formData, {
            }).then(result => {
                const url_2c2p = result.data
                console.log(url_2c2p)
                window.open(`${url_2c2p}`,'_top')
            }).catch(err => console.log('err', err))
        }

    }
    useEffect(() => {
        if (!localStorage.getItem('auth')) {
            alert('กรุณา login ก่อนสมัครแพ็คเกจ')
            window.location.href = '/logout'
        }
        axios.post(`${process.env.REACT_APP_API_CONTENT2}/userinfo`, 'no', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('auth')}`
            },
        }).then(result => user_idRef.current = result.data.res_data.user_id)
    }, [])
    const renderPackage = props.datas.data.res_data
        .filter((result) => result.product_id == id)
        .map(data => {
            return (
                <>
                    <Row className='pt-5 pb-5'>
                        <Col sm='12' md='12' lg='4'>
                            <img className='d-block banner-img silhouette' src={data.img_web} alt={data.pack_name} />
                        </Col>
                        <Col sm='12' md='12' lg='8'>
                            <div className='package-card-bg silhouette'>
                                {IsSubmit === false &&
                                    <form onSubmit={(event) => paymentNext(event, data.product_id, data.price1, data.id)} method='post'>
                                        <Row>
                                            <Col sm='12' md='12' lg='6'>
                                                <Row>
                                                    <Col xs='12'>
                                                        <h2 className='ml-4 pt-3 mb-3'>รายละเอียดคำสั่งซื้อ</h2>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>รหัส</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.product_id}</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>อายุ</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.pack_day} วัน</h5>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='6'>
                                                                <h5 className='ml-4'>รับชมพร้อมกันสูงสุด</h5>
                                                            </Col>
                                                            <Col xs='6'>
                                                                <h5 className=''>: {data.device_amount} เครื่อง</h5>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm='12' md='12' lg='12'>
                                                        <Row>
                                                            <Col xs='12'>
                                                                <h2 className='ml-4 mt-3 mb-3'>ช่องทางการชำระ</h2>
                                                                <Row>
                                                                    <Col xs='12'>
                                                                        <input type="radio" className='ml-5 mr-1' id="b2c2pjpg" name="payment" value="b2c2p" onChange={inputChange} />
                                                                        <label className='d-inline' for='b2c2pjpg'><img src={b2c2pjpg} style={{ 'width': '30%' }} /> 2c2p</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <div className='warning-input' style={{ width: '100%' }}>
                                                                <Col xs='12'>
                                                                    <Input
                                                                        name='email'
                                                                        className='mb-2 mt-2 packRegis-input'
                                                                        type='email'
                                                                        placeholder='อีเมล'
                                                                        required
                                                                        onChange={inputChange}
                                                                    />
                                                                </Col>
                                                                <Col xs='12'>
                                                                    <Input
                                                                        name='phone'
                                                                        className='mb-2 packRegis-input'
                                                                        type='tel'
                                                                        placeholder='เบอร์โทรศัพท์ (กรอกเบอร์ที่ผูกกับช่องทางการชำระ)'
                                                                        pattern='[0-9]{10}'
                                                                        required
                                                                        onChange={inputChange}
                                                                    />
                                                                </Col>
                                                                <Col xs='12'>
                                                                    <Input
                                                                        name='pricetest'
                                                                        className='mb-2 packRegis-input'
                                                                        type='text'
                                                                        placeholder='1'
                                                                        required
                                                                        onChange={inputChange}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm='12' md='12' lg='6'>
                                                <div className='warning'>
                                                    <div className='m-2'>
                                                        <p><span>หมายเหตุ：</span>ระบบปฎิบัติการอื่นๆ นอกเหนือจาก Android และ IOS ยังอยู่ในขั้นตอนการพัฒนาเพื่อรองรับการใช้งาน ดังนั้นสำหรับอุปกรณ์ของ Huawei,Oppo และ Xiaomi บางรุ่นยังไม่สามารถรองรับการทำงานที่สมบูรณ์แบบได้ในขณะนี้
                                                        </p>
                                                        <p>***ก่อนตัดสินใจซื้อแพ็คเกจลูกค้าสามารถทดลองการใช้งาน รับชมฟรี!! ทุกเรื่องสองตอน***</p>
                                                        <p>
                                                            *ทางบริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงรวมถึงแก้ไขรายละเอียดแพ็คเกจ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                        </p>
                                                        <input type='checkbox' required />
                                                        <span> ยอมรับ (ข้าพเจ้าได้อ่าน และ ยอมรับ <Link to={'/policy/term'} className='footer_btn'>ข้อกำหนดการใช้งานและเงื่อนไข</Link> และ <Link to={'/policy/privacy'} className='footer_btn'>นโยบายความเป็นส่วนตัวของMVHUB)</Link></span>
                                                    </div>
                                                </div>
                                                <div className='warning-btn'>
                                                    <Button className='mt-1 packRegis-btn' type='submit' color='success' block>ถัดไป</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                }
                                {IsSubmit == 'b2c2p' &&
                                    <>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h3 className='text-center text-yellow'>2c2p</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className='warning-input' style={{ width: '100%' }}>
                                                    <Col>
                                                        <form name='b2c2pform' id='b2c2pform' onSubmit={(event) => paymentSubmit(event, data.product_id, data.price1, data.id)} action={`${process.env.REACT_APP_BASEURL_2c2p}`} method='post'>
                                                            <input type={'hidden'} id='merchantID' name='merchantID' value={`${process.env.REACT_APP_MERCHANTID_2c2p}`} />
                                                            <input type={'hidden'} id='invoiceNo' name='invoiceNo' value={new Date().getTime() + input.email.slice(0, 2)} />
                                                            <input type={'hidden'} id='amount' name='amount' value={'0000000000'+ data.price1 } />
                                                            <input type={'hidden'} id='currencyCode' name='currencyCode' value='THB' />
                                                            <input type={'hidden'} id='request3DS' name='request3DS' value='Y' />
                                                            <input type={'hidden'} id='userDefined1' name='userDefined1' value={user_idRef.current} />
                                                            <input type={'hidden'} id='userDefined2' name='userDefined2' value={input.email} />
                                                            <input type={'hidden'} id='userDefined3' name='userDefined3' value={input.phone} />
                                                            <input type={'hidden'} id='userDefined4' name='userDefined4' value='website' />
                                                            <input type={'hidden'} id='userDefined5' name='userDefined5' value={data?.product_id} />
                                                            <input type={'hidden'} id='locale' name='locale' value='th' />
                                                            <input type={'hidden'} id='frontendReturnUrl' name='frontendReturnUrl' value={`${process.env.REACT_APP_2c2p_return_gen}`} />
                                                            <input type={'hidden'} id='backendReturnUrl' name='backendReturnUrl' value={`${process.env.REACT_APP_2c2p_return_gen_back}`} />
                                                            <input type={'hidden'} id='nonceStr' name='nonceStr' value={new Date().getTime()} />
                                                            <Button type='submit' color='success' block>ชำระเงิน</Button>
                                                        </form>
                                                    </Col>
                                                </div>
                                            </Row>
                                        </Container>
                                    </>
                                }
                            </div>
                        </Col >
                    </Row >
                </>
            )
        })
    return (
        <>
            <div className='bg-img'>
                <Container className='pt-5 package-container'>
                    <Row>
                        {renderPackage}
                    </Row>
                </Container>
            </div>
        </>
    )
}