import React from 'react'

export default function GenUrl() {

    return (
        <div className='bg-img'>
            <h1 className='pt-5 text-yellow text-center'>2c2p Redirect.....</h1>
        </div>
    )
}
