import React, { useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom';
import VideoPlayer from 'components/ShakaPlayer/VideoPlayer';
import axios from 'axios';
import menu from '../../assets/img/body/svg/reply_white_48dp.svg'

// import { getBrowser } from 'getBrowser';
import FairPlay from 'components/ShakaPlayer/FairPlay';
import { getBrowser } from 'getBrowser';

function Ep() {
    let { id, ep } = useParams();
    const infoRef = useRef()
    const datasRef = useRef()
    const [nextUrl, setNextUrl] = useState()
    const [previousUrl, setPreviousUrl] = useState()
    const licenseRef = useRef()
    const lastEpRef = useRef()
    const [poster, setPoster] = useState()
    const [uservip, setUserVip] = useState()
    const [linkads, setLinkAds] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [currentEp, setCurrentEp] = useState(parseInt(ep.split('.')[1]))
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const videoRef = useRef(null);
    const adContainerRef = useRef(null);
    let adsManager = null;

    useEffect(() => {
        if (id && ep) {
            if (localStorage.getItem('auth')) {
                axios.post(`${process.env.REACT_APP_API_CONTENT2}/serie/episode`, {
                    vod_id: parseInt(id),
                    page: 'main'
                }, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('auth')}`
                    },
                }).then(result => {
                    infoRef.current = result.data.res_data
                    if (ep === 'END') {
                        setCurrentEp(`${result.data.res_data.episode.length}`)
                    }
                    lastEpRef.current = result.data.res_data.episode.length
                    licenseRef.current = result.data.res_data.ch_name
                    setPoster(result.data.res_data.imgX1)
                    setUserVip(result.data.res_data.user_vip)
                    const data_link_ads = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostlongpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator='
                    setLinkAds(data_link_ads)//setLinkAds(result.data.res_data.link_ads)setLinkAds(result.data.res_data.link_ads)
                    datasRef.current = result.data.res_data.episode.filter(data => data.ep_name == ep && data.premium == 1 || data.ep_name === 'END' && data.premium == 1)
                    const nextIndex = result.data.res_data.episode.findIndex(x => x.ep_name == ep) + 1
                    if (result.data.res_data.episode[nextIndex]?.premium == '1') {
                        setNextUrl(result.data.res_data.episode[nextIndex]?.ep_name)
                    }
                    setPreviousUrl(result.data.res_data.episode.findIndex(x => x.ep_name == ep))
                }).finally(() => {
                    // if (datasRef.current[0].ep_link.split('.').pop() == 'm3u8') {
                    //     datasRef.current[0].ep_link = datasRef.current[0].ep_link.replace('prod_mvhub_v2', 'prod_mvhub_v2_1')
                    // }
                    setIsLoading(false)
                    initializeIMA();
                        if (adsManager) {
                            adsManager.destroy();
                        }
                })
            } else {
                axios.post(`${process.env.REACT_APP_API_CONTENT2}/guest/episode`, {
                    vod_id: parseInt(id),
                    page: 'guest',
                    device: getBrowser()
                }).then(result => {
                    infoRef.current = result.data.res_data
                    if (ep === 'END') {
                        setCurrentEp(`${result.data.res_data.episode.length}`)
                    }
                    lastEpRef.current = 2
                    licenseRef.current = result.data.res_data.ch_name
                    setPoster(result.data.res_data.imgX1)
                    const data_link_ads = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostlongpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator='
                    setLinkAds(data_link_ads)//setLinkAds(result.data.res_data.link_ads)
                    //setLinkAds(result.data.res_data.link_ads)
                    datasRef.current = result.data.res_data.episode.filter(data => data.ep_name == ep && data.premium == 1)
                    const nextIndex = result.data.res_data.episode.findIndex(x => x.ep_name == ep) + 1
                    setNextUrl(result.data.res_data.episode[nextIndex]?.ep_name)
                    setPreviousUrl(result.data.res_data.episode.findIndex(x => x.ep_name == ep))
                })
                    .finally(() => {
                        // if (datasRef.current[0].ep_link.split('.').pop() == 'm3u8') {
                        //     datasRef.current[0].ep_link = datasRef.current[0].ep_link.replace('prod_mvhub_v2', 'prod_mvhub_v2_1')
                        // }
                        if (datasRef.current[0].premium == 1) {
                            setIsLoading(false)
                        }
                        initializeIMA();
                            if (adsManager) {
                                adsManager.destroy();
                            }
                    })
            }
        }
    }, []);

    const initializeIMA = () => {
        const adDisplayContainer = new window.google.ima.AdDisplayContainer(adContainerRef.current, videoRef.current);
        adDisplayContainer.initialize();
    
        const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);
        adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded, false);
        adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError, false);
    
        // Request VAST/VMAP Ads
        const adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = linkads; // Replace with your VAST/VMAP ad tag URL
    
        adsRequest.linearAdSlotWidth = 640;
        adsRequest.linearAdSlotHeight = 360;
        adsRequest.nonLinearAdSlotWidth = 640;
        adsRequest.nonLinearAdSlotHeight = 150;
    
        adsLoader.requestAds(adsRequest);
      };
    
      const onAdsManagerLoaded = (event) => {
        const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
        adsManager = event.getAdsManager(videoRef.current, adsRenderingSettings);
    
        // Add event listeners for handling ads
        adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
        adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, onContentResumeRequested);
        adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdError);
    
        try {
          adsManager.init(640, 360, window.google.ima.ViewMode.NORMAL);
          adsManager.start();
        } catch (adError) {
          console.error('AdsManager could not be started', adError);
        }
      };
    
      const onContentPauseRequested = () => {
        //videoRef.current.pause();
      };
    
      const onContentResumeRequested = () => {
        //videoRef.current.play();
      };
    
      const onAdError = (adErrorEvent) => {
        console.error('Ad error:', adErrorEvent.getError());
        if (adsManager) {
          adsManager.destroy();
        }
      };
    
      const onError = (event) => {
        console.error('Error code', event.detail.code, 'object', event);
      };
      console.log('ads '+linkads)
      console.log('videoRef '+videoRef.current)

    if (isLoading === false) {
        return (
            <div className='bg-blank'>
                {uservip == 0 &&
                <>
                {/* <h1 style={{ color: 'white' }}>{uservip}</h1> */}
                    <div id="adContainer" className="ad-container" ref={adContainerRef} style={{ position: 'absolute', zIndex: 1 }}></div>
                </>
                }
                {currentEp < lastEpRef.current &&
                    <>
                        {getBrowser() === 4 &&
                            <FairPlay
                                id={id}
                                url={datasRef.current[0].ep_link}
                                nextUrl={`/vdo/${id}/ep/${nextUrl}`}
                                previousUrl={`/vdo/${id}/ep/EP.${previousUrl}`}
                                lastEp={lastEpRef.current}
                                license={licenseRef.current}
                                ep={[id, datasRef.current[0]?.ep_id, params.play]}
                                poster={poster}
                                datas={infoRef}
                            />
                        }
                        {getBrowser() !== 4 &&
                            <>
                                <a className="menu not-A" style={{ color: 'white' }} href={`/vdo/${id}`}><img src={menu} alt='menu' /></a>
                                <VideoPlayer
                                    id={id}
                                    url={datasRef.current[0].ep_link}
                                    nextUrl={`/vdo/${id}/ep/${nextUrl}`}
                                    previousUrl={`/vdo/${id}/ep/EP.${previousUrl}`}
                                    lastEp={lastEpRef.current}
                                    license={licenseRef.current}
                                    ep={[id, datasRef.current[0]?.ep_id, params.play]}
                                    poster={poster}
                                    datas={infoRef}
                                    ref={videoRef}
                                />
                            </>
                        }
                    </>
                }
                {
                    currentEp >= lastEpRef.current &&
                    <>
                        {getBrowser() === 4 &&
                            <FairPlay
                                id={id}
                                url={datasRef.current[0].ep_link}
                                nextUrl={`/vdo/${id}/ep/EP.0`}
                                previousUrl={`/vdo/${id}/ep/EP.${previousUrl}`}
                                lastEp={lastEpRef.current}
                                license={licenseRef.current}
                                ep={[id, datasRef.current[0]?.ep_id, params.play]}
                                poster={poster}
                                datas={infoRef}
                            />
                        }
                        {getBrowser() !== 4 &&
                            <>
                                <a className="menu not-A" style={{ color: 'white' }} href={`/vdo/${id}`}><img src={menu} alt='menu' /></a>
                                <VideoPlayer
                                    id={id}
                                    url={datasRef.current[0].ep_link}
                                    nextUrl={`/vdo/${id}/ep/EP.0`}
                                    previousUrl={`/vdo/${id}/ep/EP.${previousUrl}`}
                                    lastEp={lastEpRef.current}
                                    license={licenseRef.current}
                                    ep={[id, datasRef.current[0]?.ep_id, params.play]}
                                    poster={poster}
                                    datas={infoRef}
                                    ref={videoRef}
                                />
                            </>
                        }
                    </>
                }
            </div >
        )
    } else {
        return <div></div>
    }
}

export default Ep