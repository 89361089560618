import React from 'react'

function GenResult() {
    return (
        <div>
            <h1 className='pt-5 text-yellow text-center'>2c2p Result Redirect.....</h1>
                <iframe width="100%"
                        height="100%"
                        src="http://110.238.118.150/2c2p_mvhub_result.php"
                        title="2c2p"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            border: 'none',
                          }}
                        >
                </iframe>
        </div>
        )
}
export default GenResult;